import React from 'react';
import { Card, CardContent, CardMedia, Typography, Icon, Box, CardActionArea } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/system';

import { useAuth0 } from "@auth0/auth0-react";


const Overlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgb(33 65 89 / 50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontSize: '2rem',
  fontWeight: 'bold',
  textAlign: 'center',
  zIndex: 1
}));


const Course = ({ title, image, isCertified, certifiedOn, url, status }) => {

  const {
    isAuthenticated,
    logout,
    user
  } = useAuth0();
  

    console.log(status, 'status')
    return (
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {/* {status != 'active' && (
          <Overlay>
            Coming Soon
          </Overlay>
      )} */}
   
        <CardActionArea 
          component="a" 
          href={`https://online.utahsbdc.org/course/${url}`} 
          target="_blank"
          sx={{ height: '100%', display: 'flex', flexDirection: 'column', cursor: status === 'active' ? 'pointer' : 'default' }}
        >
        <CardMedia
          component="img"
          sx={{ }} // 16:9 aspect ratio
          image={image}
          alt={title}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          
          {isCertified &&(
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Icon sx={{ color: 'green' }}>
                <CheckCircleIcon />
              </Icon>
              <Typography variant="body2">
                Certified on: {certifiedOn}
              </Typography>
            </Box>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Course
