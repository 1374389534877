import axios from 'axios';

const API_URL = 'https://holy-renewal-e45d5ab32b.strapiapp.com/api'; // Replace with your Strapi API URL
// Fetch staff members with bearer token authentication
export const fetchStaffMembers = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/staff-members?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching staff members:', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};

export const fetchBranding = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/brandings?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching staff members:', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};

export const fetchHeadlines = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/headlines?populate=*`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching Headlines', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};


export const fetchImpact = async () => {
    const token = process.env.REACT_APP_API_TOKEN;
    try {
        const response = await axios.get(`${API_URL}/impact`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data.data);
        return response.data.data; // Assuming Strapi returns the list under `data.data`
    } catch (error) {
        console.error('Error fetching impact', error);
        throw error; // Re-throw the error to handle it in the component if necessary
    }
};
