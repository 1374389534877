import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";

export const PageLayout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      className="page-layout"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100%',
        '@media (min-width: 600px)': {
          width: '80%',
        },
        mx: 'auto',
        mt: isMobile ? 0 : 2,
      }}
    >
      <Box 
        className="page-layout__content"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          flexGrow: 1,
          padding: theme.spacing(2),
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
