import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Avatar, Paper, useTheme, useMediaQuery } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WorkIcon from '@mui/icons-material/Work';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { fetchImpact } from '../services/apiService';
import { PageLoader } from './page-loader';

const YTDImpact = () => {
  const [impactData, setImpactData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadImpactData = async () => {
      try {
        const data = await fetchImpact();
        setImpactData(data.attributes);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadImpactData();
  }, []);

  if (isLoading) return <PageLoader />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const formatNumber = (value) => {
    return Number(value).toLocaleString();
  };

  const formatCurrency = (value) => {
    return `$${formatNumber(value)}`;
  };

  const data = [
    { title: 'Business Starts', value: formatNumber(impactData?.businessStarts), icon: <BusinessIcon /> },
    { title: 'Capital', value: formatCurrency(impactData?.capital), icon: <AttachMoneyIcon /> },
    { title: 'Clients', value: formatNumber(impactData?.clients), icon: <PeopleIcon /> },
    { title: 'Sales', value: formatCurrency(impactData?.sales), icon: <TrendingUpIcon /> },
    { title: 'Jobs', value: formatNumber(impactData?.jobs), icon: <WorkIcon /> },
    { title: 'Hours', value: formatNumber(impactData?.hours), icon: <AccessTimeIcon /> },
  ];

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const utcDate = new Date(dateString + 'T00:00:00Z');
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
    return localDate.toLocaleDateString(undefined, options);
  };

  return (
    <Box sx={{ p: 1, m: 1, mt: 2 }}>
        <Typography variant='h5' sx={{mb: 2, fontWeight: 'bold'}}>
            Network YTD Impact
        </Typography>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <Paper elevation={2} sx={{ 
              display: 'flex', 
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center', 
              justifyContent: isMobile ? 'center' : 'flex-start',
              height: isMobile ? '120px' : '80px',
              width: '100%',
              p: 1,
              '&:hover': {
                elevation: 4,
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}>
              <Avatar sx={{ 
                bgcolor: 'primary.main', 
                mb: isMobile ? 1 : 0,
                mr: isMobile ? 0 : 2,
                ml: isMobile ? 0 : 3,
                width: isMobile ? 48 : 40,
                height: isMobile ? 48 : 40
              }}>
                {item.icon}
              </Avatar>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: isMobile ? 'center' : 'flex-start',
                maxWidth: '200px',
                width: '100%'
              }}>
                <Typography variant="subtitle2" align={isMobile ? 'center' : 'left'}>
                  {item.title}
                </Typography>
                <Typography sx={{fontSize: 16, fontWeight: 'bold'}} variant="h6" align={isMobile ? 'center' : 'left'}>
                  {item.value}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 2, textAlign: 'right' }}>
        <Typography variant="caption" color="text.secondary">
          Data Updated: {formatDate(impactData?.dateUpdated)}
        </Typography>
      </Box>
    </Box>
  );
};

export default YTDImpact;