import React, { useState, useEffect, useRef } from 'react';
import { Typography, Card, CardContent, Button, Grid, CardMedia, Divider, Box, Snackbar, useTheme, useMediaQuery } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PageLoader } from '../page-loader';
import { fetchHeadlines } from '../../services/apiService';

const getImageUrl = (thumbnailData) => {
  if (thumbnailData && thumbnailData.length > 0) {
    const image = thumbnailData[0].attributes;
    return image.formats?.medium?.url || image.formats?.small?.url || image.url;
  }
  return null;
};

const formatDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const NotionEvents = () => {
  const [headlines, setHeadlines] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [visibleCards, setVisibleCards] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadHeadlines = async () => {
      try {
        const response = await fetchHeadlines();
        console.log('API Response:', response);
        const sortedHeadlines = response.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
        setHeadlines(sortedHeadlines);
      } catch (error) {
        console.error('Error fetching headlines:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    loadHeadlines();
  }, []);

  useEffect(() => {
    if (!isLoading && headlines.length > 0) {
      headlines.forEach((_, index) => {
        setTimeout(() => {
          setVisibleCards(prev => [...prev, index]);
        }, index * 200); // 200ms delay between each card
      });
    }
  }, [isLoading, headlines]);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (isLoading) return <PageLoader />;
  if (error) return <Typography color="error">Error: {error.message}</Typography>;
  if (!headlines || headlines.length === 0) return <Typography>No headlines found.</Typography>;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2} sx={{ maxWidth: 'md', width: '100%' }}>
          {headlines.map((headline, index) => (
            <Grid item xs={12} key={headline.id}>
              <Card 
                sx={{ 
                  display: 'flex', 
                  flexDirection: isMobile ? 'column' : 'row',
                  mb: 2, 
                  width: '100%', 
                  height: isMobile ? 'auto' : 200, 
                  opacity: visibleCards.includes(index) ? 1 : 0,
                  transform: visibleCards.includes(index) ? 'translateY(0)' : 'translateY(50px)',
                  transition: 'opacity 0.5s ease, transform 1s ease',
                }}
              >
                {headline.attributes.thumbnail && (
                  <Box sx={{ 
                    width: isMobile ? '100%' : 200, 
                    height: isMobile ? 200 : '100%', 
                    position: 'relative', 
                    flexShrink: 0 
                  }}>
                    <CardMedia
                      component="img"
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                      image={getImageUrl(headline.attributes.thumbnail.data)}
                      alt={headline.attributes.title}
                    />
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                  <Typography sx={{ fontSize: 18, fontWeight: 'bold' }} variant="h5" component="div">
                    {headline.attributes.title}
                  </Typography>

                  {headline.attributes.date && (
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {formatDate(headline.attributes.date)}
                    </Typography>
                  )}

                  <Divider sx={{mt: 1, mb: 1}}></Divider>
                  <Typography sx={{ mb: 1.5, fontSize: 14, flexGrow: 1 }}>
                    {headline.attributes.description}
                  </Typography>

                  <Box>
                    {headline.attributes.link1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => openInNewTab(headline.attributes.link1)}
                        sx={{ mr: 1, mb: 1 }}
                      >
                        {headline.attributes.link1_btnText || "Read More"}
                      </Button>
                    )}

                    {headline.attributes.link2 && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => headline.attributes.link2_btnText === "Copy Link" 
                          ? copyToClipboard(headline.attributes.link2)
                          : openInNewTab(headline.attributes.link2)
                        }
                        sx={{ mb: 1 }}
                        startIcon={headline.attributes.link2_btnText === "Copy Link" ? <ContentCopyIcon /> : null}
                      >
                        {headline.attributes.link2_btnText || "Additional Link"}
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Link copied to clipboard"
      />
    </>
  );
};

export default NotionEvents;