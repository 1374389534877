import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { gsap } from 'gsap';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Home as HomeIcon,
  TrendingUp,
  CenterFocusStrong,
  EventNote,
  School as SchoolIcon,
  Campaign as CampaignIcon,
  PieChart as PieChartIcon,
  Psychology as PsychologyIcon,
  Feedback,
  Assignment,
  Podcasts as PodcastsIcon,
  Videocam as WebinarIcon,
  Verified,
  PersonAdd,
  Info as InfoIcon
} from '@mui/icons-material';

function Sidebar({ isMobile, isOpen, onClose }) {
  const [centerMarketing, setCenterMarketingOpen] = useState(false);
  const [centerClientData, setCenterClientDataOpen] = useState(false);
  const [networkInformation, setNetworkInformationOpen] = useState(false);
  const [accreditationOpen, setAccreditationOpen] = useState(false);
  const [entrepreneurAcademyOpen, setEntrepreneurAcademyOpen] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    gsap.to('.badge-dot', {
      scale: 1.1,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
      duration: 0.8,
    });
  }, []);

  const handleItemClick = (callback) => {
    callback();
    if (isMobile) {
      onClose();
    }
  };

  console.log('Sidebar rendered, isOpen:', isOpen, 'isMobile:', isMobile);

  const mainListItems = (
    <React.Fragment>
      {user.partner ? (
        <>
          <Link to={'/'}>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <EventNote />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Impact Report" />
            </ListItemButton>
          </Link>
          <Link to={'/ea-report'}>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Entrepreneur Academy" />
            </ListItemButton>
          </Link>
        </>
      ) : (
        <>
          <Link to={'/'}>
            <ListItemButton onClick={() => handleItemClick(() => {})}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText sx={{ color: '#000' }} primary="Home" />
            </ListItemButton>
          </Link>

          <ListItemButton onClick={() => handleItemClick(() => setCenterMarketingOpen(!centerMarketing))}>
            <ListItemIcon>
              <TrendingUp />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Center Marketing" />
            {centerMarketing ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={centerMarketing} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'brand-assets'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <CampaignIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Brand Assets" />
                </ListItemButton>
              </Link>
              <Link to={'/marketing-plan'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <CampaignIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Marketing Plan" />
                </ListItemButton>
              </Link>
              <Link to={'/market-segmentation'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PieChartIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Market Segmentation" />
                </ListItemButton>
              </Link>
              <Link to={'ai'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PsychologyIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="AI" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => handleItemClick(() => setCenterClientDataOpen(!centerClientData))}>
            <ListItemIcon>
              <CenterFocusStrong />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Center Client Data" />
            {centerClientData ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={centerClientData} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'client-satisfaction'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <Feedback />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Client Satisfaction Survey" />
                </ListItemButton>
              </Link>
              <Link to={'needs-assessment'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <Assignment />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Client Needs Assessment" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => handleItemClick(() => setEntrepreneurAcademyOpen(!entrepreneurAcademyOpen))}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Entrepreneur Academy" />
            {entrepreneurAcademyOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={entrepreneurAcademyOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'/podcasts'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PodcastsIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Podcasts" />
                </ListItemButton>
              </Link>
              <Link to={'/webinars'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <WebinarIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Webinars" />
                </ListItemButton>
              </Link>
              <Link to={'/ea-modules'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Modules" />
                </ListItemButton>
              </Link>
              <Link to={'/counselor-certification'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <Verified />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Counselor Certification" />
                </ListItemButton>
              </Link>
              <Link to={'/onboarding'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <PersonAdd />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Onboarding" />
                </ListItemButton>
              </Link>
              <Link to={'/ea-report'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Reports" />
                </ListItemButton>
              </Link>
              <Link to={'/ea-overview'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <InfoIcon/>
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Talking Points" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => handleItemClick(() => setAccreditationOpen(!accreditationOpen))}>
            <ListItemIcon>
              <Verified />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Accreditation" />
            {accreditationOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={accreditationOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'/accreditation/self-study'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Self Study" />
                </ListItemButton>
              </Link>
              <Link to={'/accreditation/standards'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Standards" />
                </ListItemButton>
              </Link>
              <Link to={'/accreditation/requests'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <Assignment />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Additional Requested Information" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>

          <ListItemButton onClick={() => handleItemClick(() => setNetworkInformationOpen(!networkInformation))}>
            <ListItemIcon>
              <EventNote />
            </ListItemIcon>
            <ListItemText sx={{ color: '#000' }} primary="Network Information" />
            {networkInformation ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={networkInformation} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to={'network-directory'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Network Directory" />
                </ListItemButton>
              </Link>
              <Link to={'operations-manual'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Operations Manual" />
                </ListItemButton>
              </Link>
              <Link to={'strategic-teams'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Strategic Teams" />
                </ListItemButton>
              </Link>
              <Link to={'year-to-date-impact'}>
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Year-To-Date Impact" />
                </ListItemButton>
              </Link>
              <Link to={'https://docs.google.com/spreadsheets/d/1Lj5pO4xa4nXj0xKQk81xvVBZ7tIdVb4YRDrCAcNsCY0/edit#gid=2030644010'} target="_blank" rel="noopener noreferrer">
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Resource Database" />
                </ListItemButton>
              </Link>
              <Link to={'https://drive.google.com/drive/folders/1jhhEQODe-Lb-IGTxhYSXn9EB6MUycBM6?usp=sharing'} target="_blank" rel="noopener noreferrer">
                <ListItemButton sx={{ pl: 4 }} onClick={() => handleItemClick(() => {})}>
                  <ListItemIcon>
                    <EventNote />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#000' }} primary="Google Drive Folder" />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        </>
      )}
    </React.Fragment>
  );

  return (
    <List>
      {mainListItems}
    </List>
  );
}

export default Sidebar;